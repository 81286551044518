// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class UpdateElementTransparencyReducer extends Reducer
{
	public static reducerName: string = "UpdateElementTransparency";
	public static call(_elementId: number, _transparency: number) {
		this.getReducer().call(_elementId, _transparency);
	}

	public call(_elementId: number, _transparency: number) {
		const serializer = this.client.getSerializer();
		let _elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_elementIdType, _elementId);
		let _transparencyType = AlgebraicType.createPrimitiveType(BuiltinType.Type.I32);
		serializer.write(_transparencyType, _transparency);
		this.client.call("UpdateElementTransparency", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let elementIdValue = AlgebraicValue.deserialize(elementIdType, adapter.next())
		let elementId = elementIdValue.asNumber();
		let transparencyType = AlgebraicType.createPrimitiveType(BuiltinType.Type.I32);
		let transparencyValue = AlgebraicValue.deserialize(transparencyType, adapter.next())
		let transparency = transparencyValue.asNumber();
		return [elementId, transparency];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _elementId: number, _transparency: number) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _elementId: number, _transparency: number) => void)
	{
		this.client.on("reducer:UpdateElementTransparency", callback);
	}
}


export default UpdateElementTransparencyReducer
