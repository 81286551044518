// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class AddLayoutReducer extends Reducer
{
	public static reducerName: string = "AddLayout";
	public static call(_name: string, _active: boolean) {
		this.getReducer().call(_name, _active);
	}

	public call(_name: string, _active: boolean) {
		const serializer = this.client.getSerializer();
		let _nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_nameType, _name);
		let _activeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		serializer.write(_activeType, _active);
		this.client.call("AddLayout", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let nameValue = AlgebraicValue.deserialize(nameType, adapter.next())
		let name = nameValue.asString();
		let activeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		let activeValue = AlgebraicValue.deserialize(activeType, adapter.next())
		let active = activeValue.asBoolean();
		return [name, active];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _name: string, _active: boolean) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _name: string, _active: boolean) => void)
	{
		this.client.on("reducer:AddLayout", callback);
	}
}


export default AddLayoutReducer
