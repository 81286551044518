// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class SendMessageReducer extends Reducer
{
	public static reducerName: string = "SendMessage";
	public static call(_chatMessage: string) {
		this.getReducer().call(_chatMessage);
	}

	public call(_chatMessage: string) {
		const serializer = this.client.getSerializer();
		let _chatMessageType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_chatMessageType, _chatMessage);
		this.client.call("SendMessage", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let chatMessageType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let chatMessageValue = AlgebraicValue.deserialize(chatMessageType, adapter.next())
		let chatMessage = chatMessageValue.asString();
		return [chatMessage];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _chatMessage: string) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _chatMessage: string) => void)
	{
		this.client.on("reducer:SendMessage", callback);
	}
}


export default SendMessageReducer
