// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class UpdateTextElementSizeReducer extends Reducer
{
	public static reducerName: string = "UpdateTextElementSize";
	public static call(_elementId: number, _size: number) {
		this.getReducer().call(_elementId, _size);
	}

	public call(_elementId: number, _size: number) {
		const serializer = this.client.getSerializer();
		let _elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_elementIdType, _elementId);
		let _sizeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.I32);
		serializer.write(_sizeType, _size);
		this.client.call("UpdateTextElementSize", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let elementIdValue = AlgebraicValue.deserialize(elementIdType, adapter.next())
		let elementId = elementIdValue.asNumber();
		let sizeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.I32);
		let sizeValue = AlgebraicValue.deserialize(sizeType, adapter.next())
		let size = sizeValue.asNumber();
		return [elementId, size];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _elementId: number, _size: number) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _elementId: number, _size: number) => void)
	{
		this.client.on("reducer:UpdateTextElementSize", callback);
	}
}


export default UpdateTextElementSizeReducer
