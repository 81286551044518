// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, SumType, SumTypeVariant, DatabaseTable, AlgebraicValue, ReducerEvent, Identity, Address, ClientDB, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class WidgetElement extends DatabaseTable
{
	public static db: ClientDB = __SPACETIMEDB__.clientDB;
	public static tableName = "WidgetElement";
	public elementDataId: number;
	public width: number;
	public height: number;
	public rawData: string;

	public static primaryKey: string | undefined = undefined;

	constructor(elementDataId: number, width: number, height: number, rawData: string) {
	super();
		this.elementDataId = elementDataId;
		this.width = width;
		this.height = height;
		this.rawData = rawData;
	}

	public static serialize(value: WidgetElement): object {
		return [
		value.elementDataId, value.width, value.height, value.rawData
		];
	}

	public static getAlgebraicType(): AlgebraicType
	{
		return AlgebraicType.createProductType([
			new ProductTypeElement("elementDataId", AlgebraicType.createPrimitiveType(BuiltinType.Type.U32)),
			new ProductTypeElement("width", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
			new ProductTypeElement("height", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
			new ProductTypeElement("rawData", AlgebraicType.createPrimitiveType(BuiltinType.Type.String)),
		]);
	}

	public static fromValue(value: AlgebraicValue): WidgetElement
	{
		let productValue = value.asProductValue();
		let __ElementDataId = productValue.elements[0].asNumber();
		let __Width = productValue.elements[1].asNumber();
		let __Height = productValue.elements[2].asNumber();
		let __RawData = productValue.elements[3].asString();
		return new this(__ElementDataId, __Width, __Height, __RawData);
	}

}

export default WidgetElement;
