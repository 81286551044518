// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, SumType, SumTypeVariant, DatabaseTable, AlgebraicValue, ReducerEvent, Identity, Address, ClientDB, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";
// @ts-ignore
import { PermissionLevel } from "./permission_level";

export class Permissions extends DatabaseTable
{
	public static db: ClientDB = __SPACETIMEDB__.clientDB;
	public static tableName = "Permissions";
	public identity: Identity;
	public permissionLevel: PermissionLevel;

	public static primaryKey: string | undefined = "identity";

	constructor(identity: Identity, permissionLevel: PermissionLevel) {
	super();
		this.identity = identity;
		this.permissionLevel = permissionLevel;
	}

	public static serialize(value: Permissions): object {
		return [
		Array.from(value.identity.toUint8Array()), PermissionLevel.serialize(value.permissionLevel)
		];
	}

	public static getAlgebraicType(): AlgebraicType
	{
		return AlgebraicType.createProductType([
			new ProductTypeElement("identity", AlgebraicType.createProductType([
			new ProductTypeElement("__identity_bytes", AlgebraicType.createArrayType(AlgebraicType.createPrimitiveType(BuiltinType.Type.U8))),
		])),
			new ProductTypeElement("permissionLevel", PermissionLevel.getAlgebraicType()),
		]);
	}

	public static fromValue(value: AlgebraicValue): Permissions
	{
		let productValue = value.asProductValue();
		let __Identity = new Identity(productValue.elements[0].asProductValue().elements[0].asBytes());
		let __PermissionLevel = PermissionLevel.fromValue(productValue.elements[1]);
		return new this(__Identity, __PermissionLevel);
	}

	public static *filterByIdentity(value: Identity): IterableIterator<Permissions>
	{
		for (let instance of this.db.getTable("Permissions").getInstances())
		{
			if (instance.identity.isEqual(value)) {
				yield instance;
			}
		}
	}

	public static findByIdentity(value: Identity): Permissions | undefined
	{
		return this.filterByIdentity(value).next().value;
	}


}

export default Permissions;
