// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class UpdateWidgetElementRawDataReducer extends Reducer
{
	public static reducerName: string = "UpdateWidgetElementRawData";
	public static call(_elementId: number, _rawData: string) {
		this.getReducer().call(_elementId, _rawData);
	}

	public call(_elementId: number, _rawData: string) {
		const serializer = this.client.getSerializer();
		let _elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_elementIdType, _elementId);
		let _rawDataType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_rawDataType, _rawData);
		this.client.call("UpdateWidgetElementRawData", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let elementIdValue = AlgebraicValue.deserialize(elementIdType, adapter.next())
		let elementId = elementIdValue.asNumber();
		let rawDataType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let rawDataValue = AlgebraicValue.deserialize(rawDataType, adapter.next())
		let rawData = rawDataValue.asString();
		return [elementId, rawData];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _elementId: number, _rawData: string) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _elementId: number, _rawData: string) => void)
	{
		this.client.on("reducer:UpdateWidgetElementRawData", callback);
	}
}


export default UpdateWidgetElementRawDataReducer
