// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class DeleteAllLayoutsReducer extends Reducer
{
	public static reducerName: string = "DeleteAllLayouts";
	public static call(_preserveElements: boolean) {
		this.getReducer().call(_preserveElements);
	}

	public call(_preserveElements: boolean) {
		const serializer = this.client.getSerializer();
		let _preserveElementsType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		serializer.write(_preserveElementsType, _preserveElements);
		this.client.call("DeleteAllLayouts", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let preserveElementsType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		let preserveElementsValue = AlgebraicValue.deserialize(preserveElementsType, adapter.next())
		let preserveElements = preserveElementsValue.asBoolean();
		return [preserveElements];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _preserveElements: boolean) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _preserveElements: boolean) => void)
	{
		this.client.on("reducer:DeleteAllLayouts", callback);
	}
}


export default DeleteAllLayoutsReducer
